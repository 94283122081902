import React from 'react'
import styled from 'styled-components'

import Navbar from './navbar'
import TopLogo from './top-logo'
import Intro from './intro'

import headerBG from '../images/header.png'

const HeaderWrapper = styled.div`
  position: relative;
  @media (min-width: 992px) {
    padding-top: 50px;
  }
  @media (min-width: 1600px) {
    padding-top: 50px;
  }
  @media (min-width: 1381px) and (max-width: 1599px) {
    padding-top: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    padding-top: 35px;
  }
  @media (max-width: 991px) {
    overflow: hidden;
  }
`

const HeaderBG = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10%;
  background-image: url(${headerBG});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  @media (min-width: 1200px) {
    top: -22%;
    bottom: 10%;
    background-size: contain;
  }
`

const Header = (props) => {
  return (
    <HeaderWrapper>
      {props.page !== 'zwyciezcy' && <HeaderBG />}
      <Navbar page={props.page} location={props.location} />
      <TopLogo page={props.page} />
      <Intro page={props.page} />
    </HeaderWrapper>
  )
}

export default Header

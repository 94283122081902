import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
} from './elements'

import logoImage from '../images/logo.svg'
import logoImageWinner from '../images/logo-winner.svg'

const TopLogoWrapper = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`

const TopLogoContainer = styled.div`
  ${ContainerMixin}
  ${PaddingsContainerMixin}
`

const TopLogoRow = styled.div`
  ${RowMixin}
`

const TopLogoCol = styled.div`
  ${ColMixin}
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: center;
`

const TopLogoLogo = styled(Link)`
  display: inline-block;
  width: 380px;
  height: 85px;
  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  @media (min-width: 1600px) {
    width: 355px !important;
    height: 95px !important;
    margin-top: 80px;
  }
  @media (min-width: 1381px) and (max-width: 1599px) {
    margin-top: 75px;
    margin-bottom: 15px;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    margin-top: 65px;
    margin-bottom: 10px;
  }
  @media (min-width: 992px) {
    display: flex;
    order: 2;
    width: 285px;
    height: 64px;
    margin-right: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 100px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 180px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 160px;
    height: 75px;
  }
  @media (max-width: 575px) {
    width: 100px;
    height: 60px;
  }
`

const TopLogo = (props) => {
  return (
    <TopLogoWrapper>
      <TopLogoContainer>
        <TopLogoRow>
          <TopLogoCol>
            <TopLogoLogo to="/" page={props.page} />
          </TopLogoCol>
        </TopLogoRow>
      </TopLogoContainer>
    </TopLogoWrapper>
  )
}

export default TopLogo

import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'
import Sticky from 'react-stickynode'

import { HeaderContainerMixin } from './elements'

import logoImage from '../images/logo.svg'
import logoImageWinner from '../images/logo-winner.svg'
import hamburgerImage from '../images/hamburger.svg'

const NavbarNav = styled.nav`
  ${HeaderContainerMixin}
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  /* transition: background-color 0.2s ease; */
  ${(props) =>
    props.stickyStatus &&
    `
    background-color: #fff;
  `}
  @media (max-width: 991px) {
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid ${(props) => props.theme.colorGreen};
  }
`

const NavbarNavLogo = styled(Link)`
  display: inline-block;
  width: 380px;
  height: 85px;
  /* ${(props) =>
    props.page !== 'zwyciezcy'
      ? `
    background-image: url(${logoImage});
  `
      : `
    background-image: url(${logoImageWinner});
  `} */
  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  @media (min-width: 1600px) {
    width: 355px !important;
    height: 95px !important;
    margin-top: 80px;
  }
  @media (min-width: 1381px) and (max-width: 1599px) {
    margin-top: 75px;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    margin-top: 65px;
  }
  @media (min-width: 992px) {
    display: none;
    /* display: flex; */
    order: 2;
    width: 285px;
    height: 64px;
    margin-right: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 100px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 180px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 160px;
    height: 75px;
  }
  @media (max-width: 575px) {
    width: 100px;
    height: 60px;
  }
`

const NavbarNavCollapse = styled.div`
  display: flex;
  flex-basis: auto;
  /* flex-grow: 1; */
  align-items: center;
  @media (min-width: 992px) {
    justify-content: center;
    order: 1;
    width: 100%;
  }
  @media (max-width: 991px) {
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
    padding-top: 20px;
    padding-left: 7.89%;
    padding-right: 5.26%;
    padding-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colorGreen};
    transition: 0.2s ease;
    z-index: 200;
  }
`

const NavbarNavList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  @media (max-width: 991px) {
    display: block;
  }
`

const NavbarNavListItem = styled.li`
  display: inline-block;
  @media (max-width: 991px) {
    display: block;
  }
`

const NavbarNavListItemLink = styled(LinkScroll)`
  display: inline-block;
  color: ${(props) => props.theme.colorGreen};
  font-size: 22px;
  font-family: ${(props) => props.theme.fontMetaPro};
  font-weight: 700;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 15px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

const NavbarButton = styled.div`
  position: absolute;
  top: 50%;
  right: 5.26%;
  width: 36px;
  height: 36px;
  background-image: url(${hamburgerImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -18px;
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
`

const Navbar = (props) => {
  const [open, setOpen] = useState(false)
  const [stickyStatus, setStickyStatus] = useState(false)

  const setToggleOpen = (open) => {
    setOpen(!open)
  }

  const setStickyStatusState = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setStickyStatus(true)
    } else {
      setStickyStatus(false)
    }
  }

  return (
    <Sticky onStateChange={setStickyStatusState}>
      <NavbarNav stickyStatus={stickyStatus}>
        <NavbarNavLogo to="/" page={props.page} />
        <NavbarButton onClick={() => setToggleOpen(open)} />
        <NavbarNavCollapse open={open}>
          <NavbarNavList>
            {props.location.pathname === '/zwyciezcy' && (
              <NavbarNavListItem>
                <NavbarNavListItemLink to="/" as={Link}>
                  strona główna
                </NavbarNavListItemLink>
              </NavbarNavListItem>
            )}
            <NavbarNavListItem>
              {props.location.pathname === '/' ? (
                <NavbarNavListItemLink
                  href="#awards"
                  to="awards"
                  onClick={() => setToggleOpen(open)}
                  smooth
                >
                  nagrody
                </NavbarNavListItemLink>
              ) : (
                <NavbarNavListItemLink
                  to="/#awards"
                  onClick={() => setToggleOpen(open)}
                  as={Link}
                >
                  nagrody
                </NavbarNavListItemLink>
              )}
            </NavbarNavListItem>
            <NavbarNavListItem>
              {props.location.pathname === '/' ? (
                <NavbarNavListItemLink
                  href="#rules"
                  to="rules"
                  onClick={() => setToggleOpen(open)}
                  smooth
                >
                  zasady
                </NavbarNavListItemLink>
              ) : (
                <NavbarNavListItemLink
                  to="/#rules"
                  onClick={() => setToggleOpen(open)}
                  as={Link}
                >
                  zasady
                </NavbarNavListItemLink>
              )}
            </NavbarNavListItem>
            {props.location.pathname === '/' && (
              <NavbarNavListItem>
                <NavbarNavListItemLink to="/zwyciezcy" as={Link}>
                  zwycięzcy
                </NavbarNavListItemLink>
              </NavbarNavListItem>
            )}
            <NavbarNavListItem>
              <NavbarNavListItemLink
                href="/regulamin-akcji.pdf"
                target="_blank"
                as="a"
                onClick={() => setToggleOpen(open)}
              >
                regulamin
              </NavbarNavListItemLink>
            </NavbarNavListItem>
            <NavbarNavListItem>
              <NavbarNavListItemLink
                href="#contacts"
                to="contacts"
                onClick={() => setToggleOpen(open)}
                offset={-50}
                smooth
              >
                kontakt
              </NavbarNavListItemLink>
            </NavbarNavListItem>
          </NavbarNavList>
        </NavbarNavCollapse>
      </NavbarNav>
    </Sticky>
  )
}

export default Navbar

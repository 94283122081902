import React from 'react'
import styled from 'styled-components'
import arrowOneYellowBG from '../images/arrow-one-yellow.png'
import arrowTwoYellowBG from '../images/arrow-two-yellow.png'

const IntroAnimateArrowsInnerWrapper = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  text-align: center;
  color: red;
  font-size: 100px;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1199px) {
    top: 18%;
  }
  @media (max-width: 575px) {
    top: 6%;
  }
`

const IntroAnimateArrowsItem = styled.div`
  position: relative;
  padding-top: 4.5%;
  margin-bottom: 13%;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 6%;
    margin-bottom: 17%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 6%;
    margin-bottom: 17%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 6.5%;
    margin-bottom: 18%;
  }
  @media (max-width: 575px) {
    padding-top: 12%;
    margin-bottom: 18%;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
    ${(props) =>
      props.currentArrow === 7 &&
      `
      opacity: 1!important;
    `}
  }
  &:before {
    background-image: url(${arrowOneYellowBG});
    ${(props) =>
      props.currentArrow <= props.currentArrows[0] &&
      `
      opacity: 0;
    `}
  }
  &:after {
    background-image: url(${arrowTwoYellowBG});
    ${(props) =>
      props.currentArrow <= props.currentArrows[1] &&
      `
      opacity: 0;
    `}
  }
`

class IntroAnimateArrows extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentCount: 1 }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 300)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  timer() {
    let currentCount = this.state.currentCount

    if (this.state.currentCount === 7) {
      currentCount = 0
    }

    if (this.state.currentCount >= 0 && this.state.currentCount <= 6) {
      currentCount = this.state.currentCount + 1
    }

    this.setState({
      currentCount: currentCount,
    })
  }

  render() {
    return (
      <IntroAnimateArrowsInnerWrapper>
        <IntroAnimateArrowsItem
          currentArrow={this.state.currentCount}
          currentArrows={[1, 2]}
        />
        <IntroAnimateArrowsItem
          currentArrow={this.state.currentCount}
          currentArrows={[3, 4]}
        />
        <IntroAnimateArrowsItem
          currentArrow={this.state.currentCount}
          currentArrows={[5, 6]}
        />
      </IntroAnimateArrowsInnerWrapper>
    )
  }
}

export default IntroAnimateArrows

import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { RowMixin, ColMixin } from './elements'
import IntroAnimateArrows from './intro-animate-arrows'

import headerWinnerBG from '../images/header-winner.svg'

const IntroWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 50px;
  ${(props) =>
    props.page === 'zwyciezcy' &&
    `
    padding-bottom: 70px;
  `}
  @media (min-width: 1600px) {
    padding-top: 40px;
  }
  @media (min-width: 1381px) and (max-width: 1599px) {
    padding-top: 35px;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    padding-top: 30px;
  }
  &:before {
    ${(props) =>
      props.page === 'zwyciezcy' &&
      `
      content: "";
    `}
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-image: url(${headerWinnerBG});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    @media (min-width: 1200px) {
      z-index: 10;
    }
  }
`

const IntroRow = styled.div`
  ${RowMixin}
`

const IntroCol = styled.div`
  ${ColMixin}
`

const IntroColTitle = styled(IntroCol)`
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
`

const IntroTitle = styled.h1`
  color: ${(props) => props.theme.textColor};
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 0px !important;
  font-weight: 900;
  text-align: center;
  font-style: italic;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
`

const IntroTitleImage = styled.div`
  box-sizing: border-box;
  margin: 0 auto 80px auto;
  max-width: 90%;
  position: relative;
  @media (min-width: 1600px) {
    margin-bottom: 60px;
    ${(props) =>
      props.page === 'zwyciezcy' &&
      `
      margin-top: 30px;
    `}
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-bottom: 50px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
  }
`

const IntroColDesc = styled(IntroCol)`
  display: flex;
  align-items: flex-end;
  font-size: 22px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const IntroDescWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  ${(props) =>
    props.page === 'zwyciezcy' &&
    `
    position: relative;    
    z-index: 20;
  `}
  p {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;
    @media (min-width: 1600px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 32px;
        padding-left: 16.5%!important;
        padding-right: 16.5%!important;
      `
          : `
        font-size: 23px;
      `}
    }
    @media (min-width: 1381px) and (max-width: 1599px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 32px;
        padding-left: 16.5%!important;
        padding-right: 16.5%!important;
      `
          : `
        font-size: 22px;
      `}
    }
    @media (min-width: 1200px) and (max-width: 1380px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 32px;
        padding-left: 16.5%!important;
        padding-right: 16.5%!important;
      `
          : `
        font-size: 20px;
      `}
    }
    @media (min-width: 992px) {
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
          padding-left: 15%!important;
          padding-right: 15%!important;
      `}
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 32px;
        padding-left: 12.5%!important;
        padding-right: 12.5%!important;
      `
          : `
        font-size: 22px;
      `}
    }
    @media (min-width: 768px) and (max-width: 991px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 26px;
        padding-left: 10.5%!important;
        padding-right: 10.5%!important;
      `
          : `
        font-size: 20px;
      `}
    }
    @media (min-width: 576px) and (max-width: 767px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 24px;
        padding-left: 8.5%!important;
        padding-right: 8.5%!important;
      `
          : `
        font-size: 18px;
      `}
    }
    @media (max-width: 575px) {
      ${(props) =>
        props.page === 'zwyciezcy'
          ? `
        font-size: 16px;
        padding-left: 5.5%!important;
        padding-right: 5.5%!important;
      `
          : `
        font-size: 17px;
      `}
    }
  }
  big {
    color: ${(props) => props.theme.colorGreen};
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-variant-numeric: lining-nums;
    @media (min-width: 1600px) {
      font-size: 44px;
    }
    @media (min-width: 1381px) and (max-width: 1599px) {
      font-size: 40px;
    }
    @media (min-width: 1200px) and (max-width: 1380px) {
      font-size: 38px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 34px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 28px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
    }
    @media (max-width: 340px) {
      font-size: 18px;
    }
  }
`

const IntroImgWrapper = styled.div`
  width: 242px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1600px) {
    width: 195px;
    margin-top: 30px;
  }
  @media (min-width: 1381px) and (max-width: 1599px) {
    width: 180px;
    margin-top: 25px;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    width: 170px;
    margin-top: 20px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    width: 242px;
  }
  @media (max-width: 991px) {
    width: 242px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 240px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 200px;
  }
  @media (max-width: 575px) {
    width: 160px;
    margin-bottom: 80px;
  }
`

const IntroBottomSpaceWrapper = styled.div`
  position: relative;
  padding-top: 84%;
  @media (min-width: 1200px) {
    padding-top: 76%;
  }
  @media (min-width: 1381px) and (max-width: 1599px) {
    padding-top: 76%;
  }
  @media (min-width: 1200px) and (max-width: 1380px) {
    padding-top: 76%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 108%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 115%;
  }
  @media (max-width: 575px) {
    padding-top: 150%;
  }
  @media (max-width: 340px) {
    padding-top: 170%;
  }
`

const Intro = (props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      headerTitle: file(relativePath: { eq: "header-title2.0.png" }) {
        childImageSharp {
          fluid(maxWidth: 1750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      headerTitleWinner: file(
        relativePath: { eq: "header-title-winner-black.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <IntroWrapper page={props.page}>
      <IntroRow>
        <IntroColTitle>
          <IntroTitle>
            {props.page !== 'zwyciezcy' && `RALLYEXPERTS`}
            {props.page === 'zwyciezcy' && `Witaj w wyścigu`}
          </IntroTitle>
          <IntroTitleImage page={props.page}>
            {props.page !== 'zwyciezcy' && (
              <Img fluid={data.headerTitle.childImageSharp.fluid} />
            )}
            {props.page === 'zwyciezcy' && (
              <Img fluid={data.headerTitleWinner.childImageSharp.fluid} />
            )}
          </IntroTitleImage>
        </IntroColTitle>
        <IntroColDesc>
          <IntroDescWrapper page={props.page}>
            {props.page !== 'zwyciezcy' && (
              <>
                <p>
                  <big>
                    1600 nagrod rzeczowych, 8 odcinków specjalnych, 60 nagród
                    głównych
                  </big>
                </p>
                <p>
                  Pokaż na co{'\u00A0'}Cię stać! <br />
                  Kupuj produkty LuK, INA, FAG i{'\u00A0'}walcz w{'\u00A0'}
                  pasjonującym wyścigu.
                  <br />
                  Przed Tobą 10 etapów i 8{'\u00A0'}ODCINKÓW SPECJALNYCH - na
                  końcu każdego czeka wyjątkowa nagroda.
                </p>
              </>
            )}
            {props.page === 'zwyciezcy' && (
              <>
                <p>
                  Sprawdź, czy jesteś jednym z{'\u00A0'}RallyExperts – kliknij w
                  {'\u00A0'}ikonę symbolizującą wybrany przez Ciebie etap i
                  {'\u00A0'}zobacz, czy zająłeś miejsce na podium!
                </p>
              </>
            )}
            {props.page !== 'zwyciezcy' && (
              <IntroImgWrapper>
                <Img fluid={data.placeholderImage.childImageSharp.fluid} />
              </IntroImgWrapper>
            )}
          </IntroDescWrapper>
        </IntroColDesc>
      </IntroRow>
      {props.page !== 'zwyciezcy' && (
        <IntroBottomSpaceWrapper>
          <IntroAnimateArrows />
        </IntroBottomSpaceWrapper>
      )}
    </IntroWrapper>
  )
}

export default Intro
